/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css, keyframes } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';
import { Direction } from '../SlideUpModal';

const openKeyFrames = (offset: number) => keyframes`
  from {
    height: 0%;
  }
  to {
    height: calc(100% - ${offset}px);
  }
`;

const closeKeyFrames = (offset: number) => keyframes`
  from {
    height: calc(100% - ${offset}px);
  }
  to {
    height: 0%;
  }
`;

interface ContainerInterface {
  close: boolean;
  offset: number;
  slideDirection: Direction;
}

const Container = styled.div<ContainerInterface>`
  ${(props) => (props.slideDirection === Direction.UP ? 'bottom: 0px' : `top: ${props.offset}px`)};
  background-color: ${({ theme }) => theme.backgroundPrimary};
  height: calc(100% - 108px);
  z-index: 1;
  width: 100%;
  overflow: scroll;
  position: absolute;
  ${MediaQuery.desktop(css`
    display: none;
  `)}
  display: flex;
  flex: 1;
  flex-direction: column;
  animation-name: ${(props) =>
    props.close ? openKeyFrames(props.offset) : closeKeyFrames(props.offset)};
  animation-duration: 0.4s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
`;

export default Container;
