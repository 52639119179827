import { Policy, Subscription } from '@rentecarlo/component-library';
import {
  CancellationAPIResponse,
  CustomerPortalResponse,
  MakeClaimResponse,
  RefundAmountAPIResponse,
} from 'state/purchases/types';

import Client from './client';
import { buildRequestUrl, RequestTypes } from './clientUtils';

const getPolicyDocuments = (uuid: string, doc: string): Promise<Blob> =>
  new Client.Builder()
    .get(`${buildRequestUrl(RequestTypes.CSI)}/api/policies/${uuid}/documents/?doc=${doc}`)
    .executeBlobResp();

const cancelPolicy = (
  policy: Policy,
  reason: string,
  comment: string,
): Promise<CancellationAPIResponse> =>
  new Client.Builder()
    .post(`${buildRequestUrl(RequestTypes.CSI)}/api/quotes/${policy.uuid}/cancel/`)
    .data({
      cancellation_reason: reason,
      customer_cancellation_comment: comment,
      expected_refund_amount: policy.refundAmount || '0',
    })
    .execute();

const getRefundAmount = (uuid: string): Promise<RefundAmountAPIResponse> =>
  new Client.Builder()
    .post(`${buildRequestUrl(RequestTypes.CSI)}/api/quotes/${uuid}/cancel-refund-amount/`)
    .execute();

const getMakeClaimChatbotUrl = (policyId: string, date: string): Promise<MakeClaimResponse> =>
  new Client.Builder()
    .post(`${buildRequestUrl(RequestTypes.CLAIMS_API)}/claims/v1`)
    .data({ policyId, incidentDateTime: date })
    .execute();

const cancelSubscription = (
  subscription: Subscription,
  reason: string,
  cancelType: string,
  comment: string,
): Promise<Record<string, never>> =>
  new Client.Builder()
    .post(`${buildRequestUrl(RequestTypes.CSI)}/subscriptions/${subscription.uuid}/cancel/`)
    .data({
      cancellation_reason: reason,
      cancel_type: cancelType,
      customer_cancellation_comment: comment,
      ...(subscription.refundAmount && { expected_refund_amount: subscription.refundAmount }),
    })
    .execute();

const getCustomerPortalUrl = (subscription: Subscription): Promise<CustomerPortalResponse> =>
  new Client.Builder()
    .get(
      `${buildRequestUrl(RequestTypes.CSI)}/subscriptions/${
        subscription.uuid
      }/update-payment-method/`,
    )
    .execute();

export const PurchasesClient = {
  getPolicyDocuments,
  cancelPolicy,
  getRefundAmount,
  getMakeClaimChatbotUrl,
  cancelSubscription,
  getCustomerPortalUrl,
};
