import React, { useState } from 'react';
import dayjs from 'dayjs';
import { CardModal, H5, P, Button } from '@rentecarlo/component-library';
import { Status, WarningIcon } from 'assets';
import { Spacer, TextContent, Content, IncedentIconContainer, StatusIcon } from './assets/styles';
import QuoteDateTimeInput from '../DateTimeInput/AccountDateTimeInput';

interface Props {
  makeClaim: (date: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  visible: boolean;
  error: boolean;
  height: string;
  close: () => void;
}

const CaptureIncidentDate: React.FC<Props> = ({ visible, error, close, makeClaim, height }) => {
  const [date, setDate] = useState('');
  const [returnedDate, setReturnedDate] = useState('');
  const [returnedTime, setReturnedtime] = useState('');
  const [isFutureDate, setIsFutureDate] = useState(false);

  const handleFinalDateTime = (newDate: string, newTime: string) => {
    setReturnedDate(newDate);
    setReturnedtime(newTime);
    const newDateTime = `${returnedDate} ${returnedTime}`;
    setDate(newDateTime);
    if (dayjs(newDateTime).isAfter(dayjs())) {
      setIsFutureDate(true);
    } else {
      setIsFutureDate(false);
    }
  };

  return (
    <CardModal visible={!!visible || !!error} maxWidth='343px' height={height} close={close}>
      <Content>
        <TextContent id='date-time-component'>
          <H5 lineHeight={25}>When did the incident happen?</H5>
          <Spacer />
          <QuoteDateTimeInput
            onChange={handleFinalDateTime}
            dateId='fromDate'
            timeId='fromTime'
            calenderIconId='fromCalendarIcon'
          />
          <IncedentIconContainer>
            {isFutureDate ? (
              <>
                <StatusIcon src={WarningIcon} />
                <P fontSize={14} color='#FF6000'>
                  The selected date and time cannot be in the future.
                </P>
              </>
            ) : (
              <>
                <StatusIcon src={Status} />
                <P fontSize={14}>Please provide the estimated date and time of your incident.</P>
              </>
            )}
          </IncedentIconContainer>
          <Spacer height={16} />
          <Button
            id='start-claim-self-serve'
            borderWidth={2}
            onClick={(event) => {
              makeClaim(date, event);
              close();
            }}
            height={64}
            borderRadius={8}
            type='button'
            disabled={!returnedDate || !returnedTime || isFutureDate}
          >
            Next
          </Button>
        </TextContent>
      </Content>
    </CardModal>
  );
};

export default CaptureIncidentDate;
