import React from 'react';
import {
  CardModal,
  Spinner,
  LinkButton,
  H5,
  P,
  PillButton,
  ArrowIcon,
} from '@rentecarlo/component-library';
import { Spacer, TextContent, Content, Warning } from './assets/styles';

interface Props {
  makeClaim: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  visible: boolean;
  error: boolean;
  close: () => void;
}

const ClaimStatusModal: React.FC<Props> = ({ visible, error, close, makeClaim }) => {
  return (
    <CardModal visible={!!visible || !!error} maxWidth='400px' close={close}>
      <Content>
        {error ? (
          <>
            <Warning />
            <TextContent>
              <H5 lineHeight={25}>We're really sorry but we can't create a claim right now...</H5>
              <Spacer />
              <P fontSize={16} lineHeight={25}>
                Please try again in a moment, if these problems persist, please visit our{' '}
                <LinkButton id='help-centre-link' large>
                  <a href='https://www.veygo.com/help'>help centre</a>
                </LinkButton>{' '}
                to contact our support team.
              </P>
              <Spacer height={16} />
              <PillButton id='tryAgain' leftImage={ArrowIcon} onClick={makeClaim}>
                Try Again
              </PillButton>
            </TextContent>
          </>
        ) : (
          <>
            <Spinner />
            <TextContent>
              <H5 lineHeight={25}>Creating a new claim</H5>
              <Spacer />
              <P fontSize={16} lineHeight={25}>
                This will only take a few moments, please bear with us.
              </P>
            </TextContent>
          </>
        )}
      </Content>
    </CardModal>
  );
};

export default ClaimStatusModal;
