import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'state';
import { Route, Redirect } from 'react-router-dom';
import Styled from 'styled-components';

import { Breadcrumb, Header, NavMenu, CrossSellModal } from 'components/organisms';
import { Notifications, Purchases, Profile, PurchaseDetail, Referrals } from 'pages';
import { Theme } from 'utils/theme';
import { Maintenance } from '@rentecarlo/component-library';
import { buildRequestUrl, RequestTypes } from '../services/clientUtils';
import { useOptimizelyFlag } from '../utils/hooks';

const RootContainer = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ theme }): Theme => theme.white};
  height: 100%;
`;

const RootContentWrapper = Styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: ${({ theme }): Theme => theme.backgroundSecondary};
`;

interface Props {
  loggedIn: boolean;
  loginChecked: boolean;
  customerInitiatedLogout: boolean;
}

const AuthRedirect: React.FC = () => {
  window.location.href = `${buildRequestUrl(RequestTypes.AUTH)}/?redirect=${window.location.href}`;
  return null;
};

export const SiteContentRaw: React.FC<Props> = ({
  loginChecked,
  loggedIn,
  customerInitiatedLogout,
}) => {
  const showMaintenancePages = useOptimizelyFlag('PERM_RAISE_ACCOUNT_MAINTENANCE_PAGE').enabled;

  if (showMaintenancePages) {
    return <Maintenance />;
  }

  if (customerInitiatedLogout) {
    return null;
  }

  return (
    <RootContainer>
      <Header />
      <Breadcrumb />
      <RootContentWrapper>
        {loginChecked && (
          <>
            <NavMenu />
            <Route path='/' exact>
              {loggedIn ? <Redirect push to='/profile' /> : <AuthRedirect />}
            </Route>
            <Route path='/profile' component={loggedIn ? Profile : AuthRedirect} />
            <Route path='/purchases' component={loggedIn ? Purchases : AuthRedirect} />
            <Route path='/notifications' component={loggedIn ? Notifications : AuthRedirect} />
            <Route path='/detailed-purchase' component={loggedIn ? PurchaseDetail : AuthRedirect} />
            <Route path='/Referrals' component={loggedIn ? Referrals : AuthRedirect} />
            <CrossSellModal />
          </>
        )}
      </RootContentWrapper>
    </RootContainer>
  );
};

const mapStateToProps = (state: AppState): Props => ({
  loggedIn: state.account.loggedIn,
  loginChecked: state.account.loginChecked,
  customerInitiatedLogout: state.account.customerInitiatedLogout,
});

export const SiteContent = connect(mapStateToProps)(SiteContentRaw);
