import { AmplifyConfig, AmplifyService } from '@rentecarlo/node-amplify-client';

function isDev(): boolean {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function initialise(): void {
  const {
    REACT_APP_DOMAIN,
    REACT_APP_COGNITO_AUDIENCE,
    REACT_APP_COGNITO_USER_POOL,
    REACT_APP_COGNITO_AWS_REGION,
  } = process.env;

  const appClientId = REACT_APP_COGNITO_AUDIENCE;
  const userPoolId = REACT_APP_COGNITO_USER_POOL;
  const region = REACT_APP_COGNITO_AWS_REGION;
  const domain = REACT_APP_DOMAIN;

  const config: AmplifyConfig = {
    region,
    userPoolId,
    appClientId,
    mandatorySignIn: false,
    domain,
    path: '/',
    expires: 365,
    secure: !isDev(),
  };

  AmplifyService.initialise(config);
}
