export const getCookie = (cookieName: string): string | undefined => {
  const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
  if (match) {
    return match[2];
  }

  return undefined;
};

export const setSessionCookie = (key: string, value: string): void => {
  const { REACT_APP_DOMAIN } = process.env;
  document.cookie = `${key}=${value};path=/;domain=${REACT_APP_DOMAIN}`;
};
