/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

export const LinkWrapper = Styled.a`
  text-decoration: none;
`;

export const SidebarFooterContainer = Styled.div`
  ${MediaQuery.desktop(css`
    display: flex;
    align-items: center;
    justify-content: center;
  `)}

  ${MediaQuery.tablet(css`
    padding-bottom: 32px;
  `)}

`;

export const SideBarStatusContainer = Styled.div`
  flex: 1;
`;

export const SidebarDivider = Styled.div`
  height: 1px;
  background: ${({ theme }) => theme.fieldSecondary};
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 16px;
  ${MediaQuery.tablet(css`
    max-width: 183px;
    margin-left: auto;
    margin-right: auto;
  `)}
`;

export const DesktopNavMenu = Styled.div`
  display: none;
  flex-direction: column;
  width: 215px;
  height: 100%;
  box-shadow: ${({ theme }) => `0 1px 3px 0 ${theme.shadowPrimary}`};
  background-color: ${({ theme }) => theme.backgroundPrimary};  
  ${MediaQuery.desktop(css`
    display: flex;
  `)}
`;

export const MenuItemsWrapper = Styled.div`
  margin-bottom: 188px;

  ${MediaQuery.mobile(css`
    flex: 1;
  `)}
`;

export const LogoutSpacer = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;
