/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState } from 'react';
import { Moment } from 'moment';
import styled, { css } from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';

import size from '../../../config/size';
import DateTimeInput from '../../atoms/DateTimeInput/DateTimeInput';
import Modal from '../../atoms/Modal/Modal';
import AccountCalendar from '../../molecules/AccountCalendar/AccountCalendar';
import ModalTimeBar from '../../molecules/ModalTimeBar/ModalTimeBar';

const Container = styled.div`
  position: relative;
`;

interface ModalProps {
  desktopMinWidth: string;
}

const ModalOuter = styled.div<ModalProps>`
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.white};
  z-index: 2;
  ${(props) => css`
    @media (min-width: ${props.desktopMinWidth}) {
      margintop: 6px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    }
  `}
`;

const ModalInner = styled.div<ModalProps>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.white};
  ${(props) => css`
    @media (min-width: ${props.desktopMinWidth}) {
      backgroundcolor: initial;
    }
  `}
`;

const QuoteCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`;

interface ComponentProps {
  dateId: string;
  timeId: string;
  calenderIconId: string;
  onChange: (date: string, time: string) => void;
}

const formatTime = (hours: number, minutes: number) => {
  const formattedHours = hours.toString().padStart(2, '0');
  return minutes === 0
    ? `${formattedHours} : 00`
    : `${formattedHours} : ${minutes.toString().padStart(2, '0')}`;
};

const QuoteDateTimeInput: React.FC<ComponentProps> = ({
  dateId,
  timeId,
  calenderIconId,
  onChange,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [selectedTime, setSelectTime] = useState<Moment | null>(null);

  const { desktopWidth: desktopMinWidth, desktopWidthInt } = size;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleSelectedDateChange = (dateTime: Moment | null) => {
    let dateTimeCloned = dateTime;
    if (selectedTime) {
      const [hours, minutes] = selectedTime.split(':').map(Number);
      dateTimeCloned = dateTime.set({ hour: hours, minute: minutes });
    } else {
      dateTimeCloned = dateTime.set({ hour: 0, minute: 0 });
      setSelectTime('00:00');
    }
    onChange(dateTimeCloned.format('Y-MM-DD'), null);
    setSelectedDate(dateTimeCloned);
  };

  const handleSelectTimeChange = (hours: number, minutes: number) => {
    const newTime = formatTime(hours, minutes);
    setSelectTime(newTime);

    if (selectedDate) {
      const dateTimeCloned = selectedDate.hours(hours).minutes(minutes);
      onChange(dateTimeCloned.format('Y-MM-DD'), dateTimeCloned.format('HH:mm'));
    }
  };

  return (
    <Container>
      <DateTimeInput
        date={selectedDate}
        time={selectedDate ? selectedTime : null}
        onCalendarClick={() => toggleModal()}
        label='from'
        dateId={dateId}
        timeId={timeId}
        calenderIconId={calenderIconId}
      />
      <ModalOuter desktopMinWidth={desktopMinWidth}>
        {modalOpen && (
          <Modal desktopMinWidth={desktopMinWidth} fullscreenOnDesktop={false}>
            <ModalInner desktopMinWidth={desktopMinWidth}>
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (window.innerWidth >= desktopWidthInt) {
                    toggleModal();
                  }
                }}
              >
                <ModalTimeBar
                  id='from'
                  onDone={() => toggleModal()}
                  onChange={handleSelectTimeChange}
                  label='Time:'
                  hours={selectedTime ? selectedTime.split(':')[0] : 0}
                  minutes={selectedTime ? selectedTime.split(':')[1] : 0}
                />
                <QuoteCalendarContainer>
                  <AccountCalendar
                    startDate={selectedDate}
                    toOrFrom='from'
                    onChange={(datetime) => handleSelectedDateChange(datetime)}
                  />
                </QuoteCalendarContainer>
              </OutsideClickHandler>
            </ModalInner>
          </Modal>
        )}
      </ModalOuter>
    </Container>
  );
};

export default QuoteDateTimeInput;
